<template>
  <section class="archive">
    <b-table
      striped
      hover
      :items="items"
    />
  </section>
</template>

<script>
import {
    BTable,
} from 'bootstrap-vue'

export default {
    components: {
        BTable,
    },
    computed: {
        items() {
            return this.$store.state.info.childrenList?.map(
                ch => ({
                    '#': ch.id,
                    ФИО: ch.full_name,
                    Группа: ch.group_name,
                    'Год выпуска': ch.group_year,
                })
            )
        },
    },
    created() {
        this.$store.dispatch('info/getChildren', {
            limit: 10,
            offset: 0,
            children_group__archived: 'True',
        })
    }
}
</script>

<style scoped lang="scss">
    .archive {
        /** not really empty */
    };
</style>
